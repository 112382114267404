import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { GlobalContext } from "../data/GlobalContext";

import NavsLink from "./NavsLink";

const MobilHeader = () => {

    const dbData = useContext(GlobalContext);

    const [hasMounted, setHasMounted] = useState(false);
    const mobileMenu = () => {
        let mobileNavToggler = document.querySelectorAll(".mobile-nav__toggler");
        if (mobileNavToggler) {
            mobileNavToggler.forEach((mobileNavTogglerBtn) => {
                mobileNavTogglerBtn.addEventListener("click", function (e) {
                    console.log("clicked");
                    document
                        .querySelector(".mobile-nav__wrapper")
                        .classList.toggle("expanded");
                    e.preventDefault();
                });
            });
        }
        // search toggler
        let searchCloser = document.querySelectorAll(".search-toggler");
        if (searchCloser) {
            searchCloser.forEach((searchCloserBtn) => {
                searchCloserBtn.addEventListener("click", function (e) {
                    document.querySelector(".search-popup").classList.toggle("active");
                    e.preventDefault();
                });
            });
        }

        //Close Mobile Menu
        let sideMenuCloser = document.querySelectorAll(".side-menu__toggler");
        if (sideMenuCloser) {
            sideMenuCloser.forEach((sideMenuCloserBtn) => {
                sideMenuCloserBtn.addEventListener("click", function (e) {
                    document
                        .querySelector(".mobile-nav__wrapper")
                        .classList.remove("expanded");
                    e.preventDefault();
                });
            });
        }
    };

    useEffect(() => {
        if (typeof window !== "undefined") {
            mobileMenu();
            setHasMounted(true);
            return () => {
                mobileMenu();
            };
        }
    }, [hasMounted]);

    if (!hasMounted) {
        return null;
    }

    return (
        <div className="mobile-nav__wrapper">
            <div className="mobile-nav__overlay side-menu__toggler side-menu__block-overlay"></div>
            <div className="mobile-nav__content">
                <span className="mobile-nav__close side-menu__toggler  side-menu__close-btn">
                    <i className="far fa-times"></i>
                </span>

                <div className="logo-box">
                    <Link to="/">
                        <img src={`${dbData.dbPrincipal.logo}`} width="220" alt="" />
                    </Link>
                </div>
                <div className="mobile-nav__container">
                    <NavsLink />
                </div>

                <ul className="mobile-nav__contact list-unstyled">
                    {
                        dbData.dbPrincipal.emails.map((email, index) => {
                            return (
                                <li key={index}>
                                    <i className="azino-icon-email"></i>
                                    <a href={`mailto:${email.email}`}>{email.email}</a>
                                </li>
                            )
                        })
                    }
                    {
                        dbData.dbPrincipal.phones.map((phone, index) => {
                            return (
                                <li key={index}>
                                    <i className="azino-icon-telephone"></i>
                                    <a href={`tel:${phone.phone}`}>{phone.phone}</a>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </div>
    )
}

export default MobilHeader;
