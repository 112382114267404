import React, { useState, useContext } from "react";
import {Link} from 'react-router-dom';
import { GlobalContext } from "../data/GlobalContext";
import { Container, Row, Col } from "react-bootstrap";
import SwiperCore, { Autoplay, Thumbs } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import testimonialImage1 from "../../assets/images/testimonial/men.png";
import testimonialImage2 from "../../assets/images/testimonial/women.png";


SwiperCore.use([Autoplay, Thumbs]);


const dbReviews = [
  {
    image: testimonialImage2,
    services: 'Efficient Roofing Services',
    name: "Krista",
    dateReviews: "Jun 15, 2022",
    text:
      "If you are looking for roofing and construction services, this company is the best one to call! He did all of the necessary services pretty fast and he also showed me everything that was done to the roof. 5 out of 5, would definitely book again!",
    star: 5
  },
  {
    image: testimonialImage2,
    services: 'Happy With The Results',
    name: "Ellen P",
    dateReviews: "Jun 12, 2022",
    text:
      "The roof of my townhouse was leaking and obviously, I needed to get a roof repair service as soon as possible. I called this company up on short notice and guess what? They catered to my roof woes and I’m really happy they did! The team went above and beyond my expectations and we are happy with the results.",
    star: 5
  },
  {
    image: testimonialImage2,
    services: 'Better Than Expected',
    name: "Silvia P.",
    dateReviews: "Jun 7, 2022",
    text:
      "Super happy with the overall experience done by this roofing company. They offer competitive prices and they met my expectations! The team was also very responsive and they were super on time when it came to appointments. If you need help with your roofs, do not hesitate to call them up.",
    star: 5
  },
  {
    image: testimonialImage2,
    services: 'Grateful For Their Services',
    name: "Cody Palmer",
    dateReviews: "Jun 4, 2022",
    text:
      "I was grateful to have such a thorough roof installation service from this company. The roof specialist came over to my place and inspected the property. I look forward to working with this roofing company once again. ",
    star: 5
  },
  {
    image: testimonialImage1,
    services: 'Recommended To Everyone',
    name: "Davis D. ",
    dateReviews: "Jun 1, 2022 ",
    text:
      "I was looking for a contractor to help me with a roof replacement project I had in mind. This company was super responsive and they also provided free estimates that were super accurate. He also communicated with us while the project was ongoing and that made us feel super comfortable. I will recommend them to everyone!",
    star: 5
  },
  {
    image: testimonialImage2,
    services: 'Dunham on Razor Roofing and Construction',
    name: "Keira S.",
    dateReviews: "May 31, 2022 ",
    text:
      "Our roof was leaking badly and called for an emergency repair. They arrived quickly and were great it fixing it. All I can say is a big thank you!",
    star: 5
  }
];

const TestimonialsTwo = () => {

  const dbData = useContext(GlobalContext);

  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const testimonialsOptions = {
    speed: 1400,
    mousewheel: true,
    slidesPerView: 1,
    autoplay: {
      delay: 5000
    }
  };
  return (
    <section className="testimonials-two">
      <Container>
        <div className="team-about__top">
          <Row className="align-items-center">
            <Col md={12} lg={7}>
              <h1 className="testimonials-two__titulo">Our reviews</h1>
              <Link className="scroll-to-target thm-btn-inverse text-capitalize" to="/contact">
                free estimate
              </Link>
            </Col>
            <Col md={12} lg={5}>
              <p className="team-about__top-text">
                {dbData.dbAbout[1].text.substring(0, 182)}
              </p>
            </Col>
          </Row>
        </div>
        <Swiper
          id="testimonials-two__carousel"
          thumbs={{ swiper: thumbsSwiper }}
          {...testimonialsOptions}
        >
          {dbReviews.map(({ image, services, dateReviews, name, text }, index) => (
            <SwiperSlide key={index}>
              <div className="testimonials-two__images">
                <img src={image} alt={name} />
              </div>
              <div className="testimonials-two__textContent">
                <p className="testimonials-two__text">{text}</p>
              </div>
              <h3 className="text-white text-center pb-2 text-capitalize">{services}</h3>
              <div className="testimonials-two__meta">
                <h3>{name}</h3>
                <span>{dateReviews}</span>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
    </section>
  );
};

export default TestimonialsTwo;
